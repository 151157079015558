import React from "react";
import img1 from "../../images/aboutusassets/gokenIndia/GIXgokengo.png";
import img2 from "../../images/aboutusassets/gokenIndia/ess.png";
import img3 from "../../images/aboutusassets/gokenIndia/ev.png";
import RMArrowBlack from "../../images/arrowBlack.png";
import RMArrowBlackEn from "../../images/arrowBlackEn.png";
// import RMArrowIn from "../../images/arrowIn.png";
import RMArrowBlackJa from "../../images/arrowBlackJa.png";
import RMArrowBlackIn from "../../images/arrowBlackIn.png";
import { useLanguage } from "../../languageContext";
import { Fade } from "react-reveal";
const GokensContribution = () => {
  const currentLanguage = useLanguage();
  return (
    <div className="gix__contribution_wrapper w-90-wrapper">
      <Fade cascade duration={1000}>
        <h2 className="semi-heading titlecase-text">
          How is Goken contributing to the future of Automotive Industry?
        </h2>
        <div className="contribution_cards_wrapper">
          {/* <div className="left_contribution_wrapper">
            <div className="gokengo_wrapper cards-wrap">
              <div className="image-wrapper">
                <img
                  src={img1}
                  alt="Close-up view of a laptop screen displaying analytical data charts, signifying data analysis work and business intelligence activities."
                />
              </div>
              <div className="content-wrapper">
                <p className="semi-para underlined-para">
                  24-hour Operations Monitoring Platform
                </p>
                <h2 className="title">GokenGO </h2>
                <p className="para">
                  A software application developed for manufacturing plants and
                  operation facilities to ensure traceability of daily work,
                  issues and resources. Our platform brings it all together:
                  GokenGo platform can enable manufacturing factory floors,
                  process plants, Oil & Gas sites, and hospital ICUs to go
                  digital. It captures the essence of continuity in daily
                  operations.
                </p>
                <div className="ul-flex-container">
                  <div className="left-side-list">
                    <ul>
                      <li>Record</li>
                      <li>Measure</li>
                      <li>Aggregate</li>
                    </ul>
                  </div>
                  <div className="right-side-list">
                    <ul>
                      <li>Analyse</li>
                      <li>Delegate</li>
                      <li>Act</li>
                    </ul>
                  </div>
                </div>
                <a href="/services/gokengo/">
                  <div className="read-more semi-para">
                    <div
                      className={`text-container sem-para uppercase-text color-${
                        currentLanguage ? currentLanguage : "en"
                      }`}
                    >
                      Read More
                    </div>
                    <div className="image-container">
                      <img
                        src={
                          currentLanguage === "en"
                            ? RMArrowBlackEn
                            : currentLanguage === "in"
                            ? RMArrowBlackIn
                            : currentLanguage === "ja"
                            ? RMArrowBlackJa
                            : RMArrowBlack
                        }
                        alt="Read More Image. This will forward to GokenGo page."
                      />
                    </div>
                  </div>
                </a>
              </div>
            </div>
          </div> */}
          <div className="right_contribution_wrapper">
            <div className="ess_wrapper cards-wrap">
              <div className="image-wrapper">
                <img
                  src={img2}
                  alt="Electric vehicle charging stations outside a modern building, illustrating sustainable transportation solutions and clean energy initiatives."
                />
              </div>
              <div className="content-wrapper">
                <div className="content-container">
                  <p className="semi-para underlined-para">
                    Engineering Design
                  </p>
                  <h2 className="title">Energy Storage Systems</h2>
                  <p className="para">
                    We provide end-to-end services such as design, prototyping,
                    testing, certification, costing & supply chain management,
                    supplier auditing, and setting up contract manufacturing.
                  </p>
                  <a href="/contactus/">
                    <div className="read-more semi-para">
                      <div
                        className={`text-container sem-para uppercase-text color-${
                          currentLanguage ? currentLanguage : "en"
                        }`}
                      >
                        Reach Out
                      </div>
                      <div className="image-container">
                        <img
                          src={
                            currentLanguage === "en"
                              ? RMArrowBlackEn
                              : currentLanguage === "in"
                              ? RMArrowBlackIn
                              : currentLanguage === "ja"
                              ? RMArrowBlackJa
                              : RMArrowBlack
                          }
                          alt="Read More Image. Black Arrow showing the navigation"
                        />
                      </div>
                    </div>
                  </a>
                </div>
              </div>
            </div>
            <div className="ev_wrapper cards-wrap">
              <div className="image-wrapper">
                <img
                  src={img3}
                  alt="Futuristic car dashboard with holographic display showing self-driving mode, highlighting advanced automotive technology and smart vehicle features."
                />
              </div>
              <div className="content-wrapper">
                <p className="semi-para underlined-para">
                  Goken’s Experience in Emerging Technologies{" "}
                </p>
                <h2 className="title">EV</h2>
                <p className="para">
                  Goken team members are part of a 24/7 operations team that
                  maintains multi-cellular and multi access connectivity to
                  vehicles. The team manages vehicle health, connectivity and
                  OTA (over-the-air) updates and supports the global fleet of
                  vehicles for our clients.
                </p>
                <a href="/contactus/">
                  <div className="read-more semi-para">
                    <div
                      className={`text-container sem-para uppercase-text color-${
                        currentLanguage ? currentLanguage : "en"
                      }`}
                    >
                      Reach Out
                    </div>
                    <div className="image-container">
                      <img
                        src={
                          currentLanguage === "en"
                            ? RMArrowBlackEn
                            : currentLanguage === "in"
                            ? RMArrowBlackIn
                            : currentLanguage === "ja"
                            ? RMArrowBlackJa
                            : RMArrowBlack
                        }
                        alt="Read More Image. Black Arrow showing the navigation"
                      />
                    </div>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </Fade>
    </div>
  );
};

export default GokensContribution;
