import React from "react";
import bgImage from "../../images/aboutusassets/gokenIndia/bgGix.png";
import mobileImage from "../../images/aboutusassets/gokenIndia/image-divider.png";
import { Fade } from "react-reveal";
const SectionDivider = () => {
  return (
    <Fade cascade duration={1000}>
      <div className="divier_image_wrapper">
        <div className="section_image_divider">
          <img src={bgImage} alt="Goken India" />
        </div>
        <div className="section_image_divider__mobile">
          <img src={mobileImage} alt="Goken India" />
        </div>
      </div>
    </Fade>
  );
};

export default SectionDivider;
