import React from "react";
import JoinTeamCTA from "../LifeAtGoken/JoinTeamCTA";
import { Link } from "gatsby";
import ArrowImage from "../../images/lifeatgoken/arrowIcon.svg";
import { Fade } from "react-reveal";

const OurStory = ({ heading, preTitle, paragraph, btnText, link }) => {
  return (
    <div className="gokenIndia__our_story w-90-wrapper p-top-4em p-bottom-4em">
      <Fade cascade duration={1000}>
        <div className="left-container ">
          <div className="text-wrapper">
            <p className="title no-margin">{preTitle}</p>
            <h2 className="heading no-margin titlecase-text">{heading}</h2>
          </div>
        </div>
        <div className="right-container">
          <div className="content-wrapper">
            <p className="para">{paragraph}</p>
            <div className="button-wrapper">
              <button className={`para btn-effect-${"in"}`}>
                <Link to={link ? link : "/"}>
                  {btnText}
                  {"  "}
                  <img
                    className={`cta-icon icon-${"in"}`}
                    src={ArrowImage}
                    alt="life at goken"
                  />
                </Link>
              </button>
            </div>
          </div>
        </div>
      </Fade>
    </div>
  );
};

export default OurStory;
