import React from "react";
// import Ticker from "react-ticker";
import Marquee from "react-fast-marquee";
const LogoTicker = ({ children }) => {
  // console.log(children);
  // return (
  //   <Ticker mode="chain" speed={15}>
  //     {/* {({ index }) => <div className="logo-wrapper">{children}</div>} */}
  //     {({ index }) => <>{children}</>}
  //   </Ticker>
  // );
  return (
    <Marquee pauseOnHover={true} gradientWidth={100} speed={90}>
      {children}
    </Marquee>
  );
  // return (
  //   <div class="marquee">
  //     <div class="track">
  //       <div class="content">{children}</div>
  //     </div>
  //   </div>
  // );
  // return (
  //   <marquee behavior="scroll" direction="" scrollamount="15">
  //     {children}
  //   </marquee>
  // );
};

export default LogoTicker;
