import React from "react";
import duration from "../../images/aboutusassets/gokenIndia/duration.png";
import location from "../../images/aboutusassets/gokenIndia/location.png";
import proddev from "../../images/aboutusassets/gokenIndia/proddev.png";
import management from "../../images/aboutusassets/gokenIndia/support.png";
import talentImg from "../../images/aboutusassets/gokenIndia/talentManagement.png";
import talentDR from "../../images/aboutusassets/gokenIndia/directRecruitement.png";
import WipeBtnBlack from "../../components/atoms/buttons/WipeBtnBlack";
import { Fade } from "react-reveal";

const HowWeServeClients = () => {
  return (
    <div className="gix__client_serve_wrapper section-br p-top-3em">
      <h2 className="heading titlecase-text">How we serve our Clients</h2>
      <Fade cascade duration={1000}>
        <div className="content-wrapper">
          <div className="left-image-wrapper">
            <div className="text-image-wrapper">
              <img src={proddev} alt="Goken India Services" />
              <h3 className="heading titlecase-text gix_services_title">
                Product <br /> Development
              </h3>
            </div>
          </div>
          <div className="right-content-wrapper">
            <div className="details_wrapper">
              <p className="para">
                Goken supports product development for OEMs throughout the
                complete product lifecycle from early stage concept development
                to start of production.​
              </p>
              <div className="server_client_details">
                <div className="serve_details_block">
                  <img src={duration} alt="Goken India Services" />
                  <p className="para">
                    6 - 18 <br />
                    months
                  </p>
                </div>
                <div className="serve_details_block client-location">
                  <img src={location} alt="Goken India Services" />
                  <p className="para ">
                    Client Location / <br /> Goken India Tech Centre
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="content-wrapper wrapper-2">
          <div className="right-content-wrapper">
            <div className="details_wrapper">
              <p className="para">
                Our consulting service focuses on client-specific requirements
                that helps them to solve engineering problems, improve quality,
                reduce cost, and bring innovative ideas to build world-class
                products
              </p>
              <div className="server_client_details">
                <div className="serve_details_block">
                  <img src={duration} alt="Goken India Services" />
                  <p className="para">
                    4 weeks - <br /> 4 months
                  </p>
                </div>
                <div className="serve_details_block">
                  <img src={location} alt="Goken India Services" />
                  <p className="para">
                    Client Location / <br /> Goken India Tech Centre
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="left-image-wrapper">
            <div className="text-image-wrapper">
              <img src={management} alt="Goken India Services" />
              <h3 className="heading titlecase-text gix_services_title">
                Engineering & <br /> Technology Consulting
              </h3>
            </div>
          </div>
        </div>
        <div className="content-wrapper">
          <div className="left-image-wrapper">
            <div className="text-image-wrapper">
              <img src={talentImg} alt="Goken India Services" />
              <h3 className="heading titlecase-text gix_services_title">
                Talent
                <br /> Management
              </h3>
            </div>
          </div>
          <div className="right-content-wrapper">
            <div className="details_wrapper">
              <p className="para">
                Our staffing solutions provide our customers with specific
                engineering talent to streamline their workflow quickly without
                any risk.​ Goken associates improve client's productivity from
                day one.
              </p>
              <div className="server_management_details">
                <h3 className="title">Models of Engagement</h3>
                <div className="models_engagement">
                  {/* <div className="model_box para">
                    <img src={talentDR} alt="Goken India Services" />
                    <p className="para no-margin">Direct Recruitment​</p>
                  </div> */}
                  <div className="model_box para">
                    {/* <img src={talentDR} alt="Goken India Services" /> */}
                    <p className="para no-margin">Staff Augmentation​</p>
                  </div>
                  <div className="model_box para">
                    {/* <img src={talentDR} alt="Goken India Services" /> */}
                    <p className="para no-margin">Contingent Staffing​</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="gix__cta_wrapper">
          <WipeBtnBlack
            fixedWidth={"300px"}
            url={"/contactus/"}
            text={"Learn More"}
          />
          {/* <p className="para">About our Services for the above focus areas</p> */}
        </div>
      </Fade>
    </div>
  );
};

export default HowWeServeClients;
