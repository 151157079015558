import { Link } from "gatsby";
import React from "react";
import { useLanguage } from "../../languageContext";
import ArrowImage from "../../images/lifeatgoken/arrowIcon.svg";

const JoinTeamCTA = ({
  heading,
  description,
  paragraph,
  link,
  className,
  buttonText,
}) => {
  const currentLanguage = useLanguage();
  return (
    <div className={`join-team-cta p-top-3em ${className}`}>
      <h3 className="semi-heading">{heading}</h3>
      <p className="para">{description}</p>
      <p className="para">{paragraph}</p>

      <Link
        state={{ location: "jobs" }}
        to={link ? link : "/"}
        className={`para btn-effect-${
          currentLanguage ? currentLanguage : "en"
        }`}
      >
        {buttonText}{" "}
        <img
          className={`cta-icon icon-${
            currentLanguage ? currentLanguage : "en"
          }`}
          src={ArrowImage}
          alt="life at goken"
        />
      </Link>
    </div>
  );
};

export default JoinTeamCTA;
