import React from "react";

const WipeBtnBlack = ({ url, text, notBtn, btnFunc, fixedWidth }) => {
  return (
    <div className={fixedWidth ? "wipe-btnfixed-width" : ""}>
      <a
        onClick={() => {
          if (notBtn) {
            btnFunc();
          } else {
            window.open(url);
          }
        }}
        className="wipe-btn-black wipe-black lft-rgt-wipe semi-para"
        href="#"
      >
        {text}
      </a>
    </div>
  );
};

export default WipeBtnBlack;
